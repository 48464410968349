var exports = {};
var b = Object.defineProperty;
var D = Object.getOwnPropertyDescriptor;
var j = Object.getOwnPropertyNames;
var M = Object.prototype.hasOwnProperty;

var N = (e, t) => {
  for (var r in t) b(e, r, {
    get: t[r],
    enumerable: !0
  });
},
    L = (e, t, r, i) => {
  if (t && typeof t == "object" || typeof t == "function") for (let s of j(t)) !M.call(e, s) && s !== r && b(e, s, {
    get: () => t[s],
    enumerable: !(i = D(t, s)) || i.enumerable
  });
  return e;
};

var v = e => L(b({}, "__esModule", {
  value: !0
}), e);

var Y = {};
N(Y, {
  Client: () => y,
  createClient: () => g,
  getDefaultClient: () => A,
  isFieldError: () => T
});
exports = v(Y);
var p = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=",
    B = /^(?:[A-Za-z\d+\/]{4})*?(?:[A-Za-z\d+\/]{2}(?:==)?|[A-Za-z\d+\/]{3}=?)?$/;

function R(e) {
  e = String(e);

  for (var t, r, i, s, o = "", a = 0, d = e.length % 3; a < e.length;) {
    if ((r = e.charCodeAt(a++)) > 255 || (i = e.charCodeAt(a++)) > 255 || (s = e.charCodeAt(a++)) > 255) throw new TypeError("Failed to execute 'btoa' on 'Window': The string to be encoded contains characters outside of the Latin1 range.");
    t = r << 16 | i << 8 | s, o += p.charAt(t >> 18 & 63) + p.charAt(t >> 12 & 63) + p.charAt(t >> 6 & 63) + p.charAt(t & 63);
  }

  return d ? o.slice(0, d - 3) + "===".substring(d) : o;
}

function I(e) {
  if (e = String(e).replace(/[\t\n\f\r ]+/g, ""), !B.test(e)) throw new TypeError("Failed to execute 'atob' on 'Window': The string to be decoded is not correctly encoded.");
  e += "==".slice(2 - (e.length & 3));

  for (var t, r = "", i, s, o = 0; o < e.length;) t = p.indexOf(e.charAt(o++)) << 18 | p.indexOf(e.charAt(o++)) << 12 | (i = p.indexOf(e.charAt(o++))) << 6 | (s = p.indexOf(e.charAt(o++))), r += i === 64 ? String.fromCharCode(t >> 16 & 255) : s === 64 ? String.fromCharCode(t >> 16 & 255, t >> 8 & 255) : String.fromCharCode(t >> 16 & 255, t >> 8 & 255, t & 255);

  return r;
}

var _ = "2.8.1";

var x = (o => (o.REQUIRED_FIELD_MISSING = "REQUIRED_FIELD_MISSING", o.REQUIRED_FIELD_EMPTY = "REQUIRED_FIELD_EMPTY", o.TYPE_EMAIL = "TYPE_EMAIL", o.TYPE_NUMERIC = "TYPE_NUMERIC", o.TYPE_TEXT = "TYPE_TEXT", o))(x || {});

function T(e) {
  return e.code in x && e.field !== void 0;
}

function O(e) {
  return e.errors !== void 0;
}

var C = e => R(JSON.stringify(e));

var P = e => {
  let t = `@formspree/core@${_}`;
  return e ? `${e} ${t}` : t;
},
    w = () => 1 * new Date(),
    u = (e, t, r) => {
  e instanceof FormData ? e.append(t, r) : e = Object.assign(e, {
    [t]: r
  });
},
    F = async ({
  stripePromise: e,
  response: t,
  responseData: r,
  payload: i,
  data: s,
  fetchImpl: o,
  request: a,
  url: d
}) => {
  let c = await e.handleCardAction(r.stripe.paymentIntentClientSecret);
  if (c.error) return {
    response: t,
    body: {
      errors: [{
        code: "STRIPE_CLIENT_ERROR",
        message: "Stripe SCA error",
        field: "paymentMethod"
      }]
    }
  };
  {
    i.paymentMethod.id || u(s, "paymentMethod", i.paymentMethod.id), u(s, "paymentIntent", c.paymentIntent.id), u(s, "resubmitKey", r.resubmitKey);
    let m = await o(d, { ...a,
      body: JSON.stringify({
        paymentIntent: c.paymentIntent.id,
        resubmitKey: r.resubmitKey
      })
    }),
        n = await m.json();
    return {
      response: m,
      body: n
    };
  }
};

function E({
  body: e,
  response: t
}) {
  return !O(e) && (e == null ? void 0 : e.error) && (e = {
    errors: [{
      message: e.error
    }]
  }), {
    body: e,
    response: t
  };
}

var U = () => navigator.webdriver || !!document.documentElement.getAttribute(I("d2ViZHJpdmVy")) || !!window.callPhantom || !!window._phantom,
    h = class {
  constructor() {
    this.loadedAt = w(), this.webdriver = U();
  }

  teardown() {}

  data() {
    return {
      loadedAt: this.loadedAt,
      webdriver: this.webdriver
    };
  }

};

var y = class {
  constructor(t = {}) {
    this.project = t.project, this.stripePromise = t.stripePromise, typeof window < "u" && this.startBrowserSession();
  }

  startBrowserSession() {
    this.session || (this.session = new h());
  }

  teardown() {
    this.session && this.session.teardown();
  }

  async submitForm(t, r, i = {}) {
    let s = i.endpoint || "https://formspree.io",
        o = i.fetchImpl || fetch,
        a = this.project ? `${s}/p/${this.project}/f/${t}` : `${s}/f/${t}`,
        d = n => n instanceof FormData ? n : JSON.stringify(n),
        c = {
      Accept: "application/json",
      "Formspree-Client": P(i.clientName)
    };

    this.session && (c["Formspree-Session-Data"] = C(this.session.data())), r instanceof FormData || (c["Content-Type"] = "application/json");
    let m = {
      method: "POST",
      mode: "cors",
      body: d(r),
      headers: c
    };

    if (this.stripePromise && i.createPaymentMethod) {
      let n = await i.createPaymentMethod();
      if (n.error) return {
        response: null,
        body: {
          errors: [{
            code: "STRIPE_CLIENT_ERROR",
            message: "Error creating payment method",
            field: "paymentMethod"
          }]
        }
      };
      u(r, "paymentMethod", n.paymentMethod.id);
      let f = await o(a, { ...m,
        body: r
      }),
          l = await f.json();
      return l && l.stripe && l.stripe.requiresAction && l.resubmitKey ? await F({
        stripePromise: this.stripePromise,
        responseData: l,
        response: f,
        payload: n,
        data: r,
        fetchImpl: o,
        request: m,
        url: a
      }) : E({
        response: f,
        body: l
      });
    } else return o(a, m).then(n => n.json().then(f => E({
      body: f,
      response: n
    }))).catch();
  }

},
    g = e => new y(e),
    A = () => (S || (S = g()), S),
    S;

0 && (exports = {
  Client,
  createClient,
  getDefaultClient,
  isFieldError
});
export default exports;
const _Client = exports.Client,
      _createClient = exports.createClient,
      _getDefaultClient = exports.getDefaultClient,
      _isFieldError = exports.isFieldError;
export { _Client as Client, _createClient as createClient, _getDefaultClient as getDefaultClient, _isFieldError as isFieldError };